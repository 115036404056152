@import 'src/styles/colors.scss';

.modal {
    position: absolute;
    left: 24px;
    top: 112px;
    width: 532px;
    z-index: 100;    
    bottom: 62px;
}

.container {
    position: relative;
    max-height: 100%;
    width: 532px;
    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: $white;
    border-radius: 20px;

    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.1);
}

.header {
    position: relative;
    padding: 24px;
    padding-bottom: 16px;
    margin: -24px -24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px 20px 0 0;
    background-color: $light-grey;
}

.userName {
    flex: 1 1;
    font-weight: bold;
    font-size: 20px;
    color: $black;
}

.logOut {
    color: rgba(16, 48, 64, 0.5);
    text-decoration: none;
    &:hover {
        color: $black;
        transition: 0.4s;
    }
}

.content {
    background-color: #fff;
    border-radius: 20px;
    padding: 0 24px 24px;
}
