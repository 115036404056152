@import 'src/styles/colors.scss';

.wrapper {
    overflow: auto;
    margin-right: -24px;
    padding-right: 24px;
}

.menuList {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 10;
}
