@import 'src/styles/colors.scss';

.filter {
    margin-bottom: 16px;
}

.categories {
    display: flex;
    justify-content: space-between;
}

.category {
    width: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;

        &:disabled {
            cursor: default;
        }
    }
}

.categoryIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    width: 56px;
    height: 56px;

    border-radius: 50%;
    border: 2px solid #f3f3f3;

    img {
        opacity: 0.3;
        transition: opacity 0.2s;

        .category._active & {
            opacity: 1;
        }
    }
}

.categoryName {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    color: $black;
}
