@import 'src/styles/colors.scss';

.container {
    display: flex;
}

.categories {
    display: flex;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    height: 28px;
}

.category {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 5px 10px;
    color: $white;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    &:first-child {
        padding-left: 13px;
        border-radius: 12px 0 0 12px;
    }

    &:last-child {
        padding-right: 13px;
        border-radius: 0 12px 12px 0;
    }
}
