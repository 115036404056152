@import 'src/styles/colors.scss';

.userAvatar {
    position: relative;
    height: 72px;
    width: 72px;
    margin-right: 20px;

    img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

._static {
    height: 32px;
    width: 32px;
    margin-right: 0;
    margin-left: 24px;
}

.editPhotoBtn {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    height: 28px;
    width: 28px;
    background-color: $white;
    border-radius: 50%;
    font-family: Material Icons;
    font-size: 16px;
    color: $blue;

    &:hover {
        color: $dark-blue;
    }
}
