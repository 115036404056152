// palette colors
$black: #103040;
$black-light: rgba(16, 48, 64, 0.15);

$grey: rgba(#103040, 0.6);
$light-grey: #f0f0f0;

$white: #ffffff;

$blue: #3d67ff;
$dark-blue: #375de5;

// app theme
$primary: $blue;
