@import 'src/styles/colors.scss';

.crimesWrapper {
    border-top: 1px solid $black-light;
    padding-top: 14px;
    padding: 0 20px 0 0;
}
.crimesList {
    margin-bottom: 16px;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.crimesItem {
    display: flex;
    flex-direction: column;
}

.crimesItemCount {
    font-weight: 500;
    font-size: 36px;
    line-height: normal;
}

.crimesOtherTitle {
    color: $black-light;
}
