@import 'src/styles/colors.scss';

.modal {
    position: fixed;
    z-index: 100;
    left: 24px;
    top: 24px;
    bottom: auto;

    &._county {
        bottom: 62px;
    }
}

.container {
    position: relative;
    height: 100%;
    padding: 24px;
    width: 532px;

    display: flex;
    flex-direction: column;

    background-color: $white;
    border-radius: 20px;

    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.1);

    .modal._county & {
        width: 450px;
    }
}

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal._opened & {
        margin-bottom: 24px;
    }
}

.logo {
    margin-right: 24px;

    img {
        width: 130px;
        height: 32px;
    }
}

.arrow {
    width: 24px;
    height: 24px;
    background: none;
    border: 0;
    background: url(./images/arrow.svg) center center / 12px 8px no-repeat;
    transition: transform 0.2s;
    margin-left: 24px;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &._opened {
        transform: rotate(180deg);
    }
}

.loginButton {
    height: 32px;
    padding: 8px 12px;
    line-height: 12px;
    margin-left: 24px;
}

.loginIcon {
    width: 12px;
    height: 12px;
    background: url(./images/login.svg) center center / contain no-repeat;
    margin-right: 5px;
}

.citiesList {
    display: flex;
    flex-direction: column;
}

.citiesListHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 14px;
    color: rgba(16, 48, 64, 0.4);
    border-bottom: 1px solid rgba(16, 48, 64, 0.1);
}

.citiesListTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
    letter-spacing: -0.5px;
}

.citiesListTitleIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url(./images/shield.svg) center center / 18px 24px no-repeat;

    &._search {
        background: url(./images/search.svg) center center / 16px 16px no-repeat;
    }

    &._loading {
        background: none;
    }
}

.spinner {
    padding: 0;

    img {
        width: 20px;
        height: 20px;
    }
}

.city {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    padding: 0;
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:hover {
        text-decoration: underline;
        color: $blue;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.cityTitleColumn {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.cityColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    white-space: nowrap;
    min-height: 20px;

    &._score {
        width: 34px;
        justify-content: flex-end;
    }

    &._business {
        width: 66px;
    }

    &._covid19 {
        width: 56px;
    }

    &._crimes {
        width: 42px;
    }
}

.search {
    display: block;
    flex: 1;

    &._county {
        display: none;
    }
}

.scoreCalculationButton {
    position: relative;
    width: 16px;
    height: 16px;
    background: none;
    border: 0;
    background: url(./images/info.svg) center center / 12px 12px no-repeat;
    margin-left: 4px;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:after {
        position: absolute;
        top: -16px;
        bottom: -16px;
        right: -16px;
        left: -16px;
        content: '';
    }
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #43a86b;
}

.dash {
    height: 2px;
    width: 12px;
    background-color: $black-light;
}
