@import 'src/styles/colors.scss';
.wrapper {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    height: 60px;
    cursor: pointer;

    &::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 40px;
        background-color: $light-grey;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:last-child {
        &::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 40px;
            background-color: $light-grey;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &:hover {
        & .textIcon {
            transition: 0.3s;
            &_businessesDataWidget {
                color: #ff9966;
            }
            &_covidDataWidget {
                color: #43a86b;
            }
            &_mobilityDataWidget {
                color: #ff6666;
            }
        }
        & .svgIcon {
            fill: #43a86b;
        }
    }

    &._disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.title {
    font-size: 12px;
    text-align: center;
    color: $grey;
    margin-bottom: 6px;
}

.icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
}

.textIcon {
    font-family: Material Icons;
    font-size: 24px;
    color: #103040;
    line-height: normal;
}

.svgIcon {
    display: flex;
    justify-content: center;
}

.weatherIcon {
    transform: scale(1.5);
}

.infoWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.textInfo {
    margin-right: 4px;
    &:last-child {
        margin-right: 0;
    }
}

.mainInfo {
    color: $black;
}
.additionalInfo {
    color: $black-light;
}
