@import 'src/styles/colors.scss';

.title {
    font-weight: bold;
    font-size: 16px;
}



.citiesList {  
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-right: -24px;
    padding-right: 24px;
}

.citiesListHeader { 
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 14px;
    color: rgba(16, 48, 64, 0.4);
    border-bottom: 1px solid rgba(16, 48, 64, 0.1);
}

.citiesListTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
    letter-spacing: -0.5px;
}

.city {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    padding: 0;
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:hover {
        text-decoration: underline;
        color: $blue;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.cityTitleColumn {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.cityColumn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    white-space: nowrap;
    min-height: 20px;

    &._score {
        width: 34px;
        justify-content: flex-end;
    }

    &._business {
        width: 66px;
    }

    &._covid19 {
        width: 56px;
    }

    &._crimes {
        width: 42px;
    }
}
