@import 'src/styles/colors.scss';
.favoritesItem {
    padding-top: 2px;
    margin-bottom: 16px;
    &._remove {
        background-color: $light-grey;
        border-radius: 10px;
    }
}
.rowWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.bookmark {
    font-family: 'Material Icons';
    font-size: 16px;
    margin-right: 8px;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}
.removeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
}
.removeToolTip {
    margin-left: 30px;
    margin-bottom: 6px;
}
