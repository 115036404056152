@import 'src/styles/colors.scss';

.title {
  font-weight: bold;
  font-size: 16px;
}

.userHistoryList {
  padding: 0;
  margin: 0;
}

.HistoryItem {
  display: flex;
  border-top: 1px solid $black-light;
  padding: 13px 0;
  cursor: pointer;
  &:hover {
      & .icon,
      .HistoryItem__innerText:first-child {
          color: $blue;
      }
      & .HistoryItem__date {
        color: $black;
      }
  }
}



.HistoryItem__innerTextWrapper {
  display: flex;
  flex-grow: 1;
}

.HistoryItem__innerText {
  font-size: 14px;
  &:not(:first-child) {
      color: $grey;
      margin-left: 16px;
      position: relative;
      &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -8px;
          height: 2px;
          width: 2px;
          border-radius: 50%;
          background-color: $grey;
      }
  }
}

.HistoryItem__date {
  color: $grey;
}
