@import 'src/styles/colors.scss';

.modal {
    position: absolute;
    left: 24px;
    top: 112px;
    width: 100%;
    max-width: 532px;
    z-index: 100;
    bottom: 62px;

    @media only screen and (min-width: 760px) {
        width: 664px;
    }
}

.container {
    position: relative;
    max-height: 100%;
    width: 532px;
    padding: 24px;

    display: flex;
    flex-direction: column;

    background-color: $white;
    border-radius: 20px;

    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.1);

    overflow-y: auto;

    @media only screen and (min-width: 760px) {
        width: 664px;
    }
}

.header {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 10px;
    justify-content: flex-start;
    margin-bottom: 28px;
}

.title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.statisticBlockTitle {
    font-weight: bold;
    font-size: 16px;
}

.statisticList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin: 19px -24px 0;
    list-style: none;
}
.statisticItem {
    padding: 0 25px;
    margin-bottom: 19px;
}

.statisticItemTitle {
    display: block;
    font-weight: 500;
    font-size: 12px;
    text-transform: capitalize;
    margin-bottom: 12px;
}
.statisticItemTable {
    border-spacing: unset;
}

.statisticItemParams {
    border-collapse: collapse;
}
.statisticItemParam {
    align-self: center;
    text-transform: capitalize;
    margin-bottom: 8px;
}
.staticItemCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-right: 10px;
}

.staticItemName {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: $grey;
}

.review {
    background-color: $light-grey;
    margin-bottom: 20px;
    padding: 10px;
}

.reviewOption {
    padding: 5px;
    margin-right: 10px;
    background-color: #fff;
    border-radius: 10px;
}

.close {
    position: absolute;
    right: 0;
    top: calc(50% - 5px);
    transform: translateY(-50%);
    background: none;
    border: 0;
    padding: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../images/close.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
    }
}
.ratingInfoWrapper {
    color: $grey;
    display: flex;
    align-items: center;
}
.toolTip {
    position: absolute;
    padding: 23px;
    font-size: 12px;
    top: 60px;
    left: 10px;
    right: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: none;
    font-weight: 400;
    line-height: 18px;
    &__displayed {
        display: flex;
    }
}
