@import 'src/styles/colors.scss';

.chart {
}

.chartPercentText {
    fill: #aaa;
    font-size: 12px;
}

.legend {
    margin: 10px 0;
}

.legendRow {
}

.legendRowDot {
}

.legendRowTitle {
}
