@import 'src/styles/colors.scss';

.container {
    position: fixed;
    z-index: 101;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    padding: 24px;
    overflow: auto;
    color: $black;

    @media screen and (max-width: 1024px) {
        padding: 16px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 16px;
    padding: 8px 0;
    font-size: 12px;
    line-height: 14px;
    color: rgba(16, 48, 64, 0.4);
    border-bottom: 1px solid rgba(16, 48, 64, 0.1);
}

.headerTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
    white-space: nowrap;
}

.city {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 20px;
    text-align: left;

    padding: 16px 0;
    background: none;
    border: 0;
    border-bottom: 1px solid rgba(16, 48, 64, 0.1);

    &:active,
    &:focus {
        cursor: pointer;
        outline: none;
    }

    &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
    }

    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.cityTitleColumn {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    @media screen and (max-width: 1024px) {
        width: 50%;
        flex: none;
    }
}

.cityColumn {
    margin-left: 16px;
    width: 250px;

    @media screen and (max-width: 1024px) {
        &._covid19,
        &._business,
        &._crimes {
            margin-top: 16px;
        }
    }

    &._score {
        text-align: center;

        @media screen and (max-width: 1024px) {
            width: 50%;
            flex: none;
            margin: 0;
            text-align: right;
        }
    }

    .header & {
        text-align: center;

        @media screen and (max-width: 1024px) {
            &._covid19,
            &._business,
            &._crimes {
                display: none;
            }

            &._score {
                text-align: right;
            }
        }
    }
}

.row {
    display: flex;
    color: $grey;

    &._black {
        color: $black;
    }
}

.title {
    text-align: right;
    width: 150px;
    margin-right: 16px;

    &._short {
        display: block;
    }

    &._long {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        &._short {
            display: none;
        }

        &._long {
            display: block;
        }
    }
}

.result {
    text-align: left;
}

.footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search {
    max-width: 30%;
    margin-right: 24px;
    min-width: 250px;
}

.close {
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../images/close.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
    }
}
