@import 'src/styles/colors.scss';

.container {
    position: absolute;
    left: 426px;
    top: 0;
    bottom: 0;
    width: 430px;
    z-index: -1;

    padding: 24px 24px 0 48px;
    background-color: $white;
    border-radius: 0 20px 20px 0;

    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;

    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eaeaea;
}

.title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.close {
    margin-left: 16px;
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../../images/close.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.content {
    flex: 1;
    margin: 0 -24px;
    padding: 24px 24px 32px;
    overflow-y: auto;
    overflow-x: hidden;
}

.cardRecord {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rating {
    margin-right: 6px;
}

.images {
    margin-top: 16px;
    display: flex;
    width: 100%;
    overflow-x: auto;

    scrollbar-width: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.image {
    width: 140px;
    margin-right: 8px;

    img {
        width: 140px;
        height: 140px;
        border-radius: 10px;
        object-fit: cover;
    }
}

.transaction {
    display: flex;
    align-items: flex-start;
    margin-right: 8px;

    &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: url(./images/tick.svg) center center / 8px 8px no-repeat;
        margin-top: 3px;
        margin-right: 4px;
    }
}

.reviews {
    margin-top: 16px;
}

.review {
    padding: 8px 12px;
    background: #f2f2f2;
    border-radius: 15px;
    font-size: 12px;
    line-height: 14px;
    color: $black;

    & + .review {
        margin-top: 4px;
    }
}

.businessRating {
    margin-bottom: 0;
}

.businessReviews {
    font-size: 10px;
    color: $grey;
}