body {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }
}

button,
input {
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-input-placeholder {
    /* Edge */
    color: #6d6d6d;
}

::placeholder {
    color: #6d6d6d;
}

.modal-wrapper {
    position: absolute;
    left: 24px;
    top: 112px;
    width: 532px;
    z-index: 100;
}
