@import 'src/styles/colors.scss';

.container {
    border: 2px solid #f3f3f3;
    border-radius: 16px;
    margin-bottom: 8px;
}

.header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    width: 100%;
}

.button {
    background: 0;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }
}

.headerTitle {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        text-decoration: underline;
    }

    .header._opened & {
        color: $blue;
    }

    .header._filterApplied &:after {
        position: absolute;
        top: -3px;
        right: -8px;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $blue;
    }

    .header._filterApplied._opened &:after {
        display: none;
    }
}

.headerBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: inherit;
    height: inherit;
}

.headerActions {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
}

.headerClearButton {
    margin: 0 8px 0 0;

    &:hover {
        text-decoration: underline;
    }
}

.content {
    padding: 8px 0;
    &_disabled {
        pointer-events: none;
        opacity: 0.5;
        user-select: none;
    }
}

.footer {
    padding: 0 16px;

    button {
        width: 100%;
    }
}

.filter {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f3f3f3;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
}

.filterTitle {
    flex: 1;
    padding-right: 16px;
}

.filterField {
    width: 280px;
}
