@import 'src/styles/colors.scss';

.favoriteButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border: 1px solid $light-grey;
    border-radius: 20px;
    transition: 0.5s;
    &:hover {
        background: $light-grey;
    }
    &._disables {
        opacity: 0.8;
        pointer-events: none;
        cursor: default;
    }
    &._nonactive {
        opacity: 0.8;
        cursor: default;
        &:hover {
            background: none;
        }
    }
}
