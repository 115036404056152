@import 'src/styles/colors.scss';

.container {
    display: flex;
}

.section {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;
    margin-right: 40px;

    &:last-child {
        margin-right: 0;
    }
}

.sectionTitle {
    color: $grey;
}

.sectionValue {
    font-size: 36px;
    line-height: 42px;
}
