@import 'src/styles/colors.scss';

.map {
    width: 100vw;
    height: 100vh;

    :local & :global .mapboxgl-popup {
        z-index: 2;
    }

    :local & :global .mapboxgl-popup-content {
        pointer-events: none;
        background: #f2f2f2;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        padding: 4px 12px;
        border-radius: 16px;
        cursor: pointer;

        color: $black;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
    }

    :local & :global .mapboxgl-popup-tip {
        display: none;
    }

    canvas {
        &:focus,
        &:active {
            outline: none;
        }
    }
}

.marker {
    background-color: #0088ff;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid white;
}

.city {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 50%;

    background-color: #0088ff;
    border: 2px solid white;

    color: #fff;
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;

    transition: background-color 0.2s;

    &:hover {
        cursor: pointer;
        z-index: 1;
    }
}

.mapControls {
    position: fixed;
    right: 24px;
    top: 0;
    top: 40%;
    display: flex;
    flex-direction: column;
}

.mapControl {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    margin-bottom: 8px;
    border: 0;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.1);
    background-color: white;
    background-position: center center;
    background-repeat: no-repeat;
    transition: background-color 0.2s;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:hover {
        background-color: #f3f3f3;
    }

    &._zoomIn {
        background-image: url(./images/zoom-in.svg);
        background-size: 14px 14px;
    }

    &._zoomOut {
        background-image: url(./images/zoom-out.svg);
        background-size: 14px 2px;
    }
}
