@import 'src/styles/colors.scss';

.ratingSelect {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid $light-grey;
    &:last-child {
        border: none;
    }
}

.ratingInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.ratingInfo {
    margin: 0 2px;
}

.ratingSelectTitle {
    font-size: 12px;
    font-weight: 500;
    flex-grow: 1;
}

.ratingSelectRadio {
    display: none;
}

.ratingSelectStar {
    font-size: 26px;
    color: $light-grey;
    cursor: pointer;
    transition: 0.3s;
}
.ratingSelectStarBad {
    color: #ff6666;
}

.ratingSelectStarGood {
    color: #ffd466;
}

.ratingSelectStarVeryGood {
    color: #43a86b;
}

.ratingSelectStarsBlock {
    position: relative;
    display: flex;
    align-items: center;
}

.ratingSelectOption {
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    font-weight: 500;
    font-size: 12px;
    color: $grey;
}

.ratingSelectStarWrapper {
    display: flex;
}
.ratingInfo {
    cursor: pointer;
}
.toolTip {
    position: absolute;
    padding: 23px;
    font-size: 12px;
    bottom: 46px;
    background-color: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    display: none;
    font-weight: 400;
    line-height: 18px;
    &__displayed {
        display: flex;
    }
}
