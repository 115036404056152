.reviewsButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 6px 10px;
    cursor: pointer;
    margin-right: 6px;

    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    &:hover {
        background: #f0f0f0;
    }
    &_disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
    }
}

.usersRatingWrapper {
    display: flex;
    flex-wrap: nowrap;
    margin-right: 10px;
}
.usersReviewsIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 11px;
    width: 11px;
    margin-right: 6px;
}

.usersRatingDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid #43a86b;
    margin-right: 4px;
    &:last-child {
        margin-right: 0;
    }
    &_active {
        background-color: #43a86b;
    }
}
