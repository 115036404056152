.section_title {
    font-weight: bold;
    font-size: 16px;
}
.title {
    font-weight: bold;
    font-size: 16px;
}
.userReviewsList {
    padding: 0 0 24px;
    display: flex;
    flex-direction: column;
}
