@import 'src/styles/colors.scss';

.modal {
    position: absolute;
    left: 24px;
    top: 112px;
    width: 532px;
    z-index: 100;
}

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 24px 0;
    background-color: $white;
    border-radius: 20px;

    max-height: calc(100vh - 112px - 64px);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
}

.title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
}

.close {
    margin-left: 16px;
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../images/close.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.content {
    flex: 1;
    margin: 0 -24px;
    padding: 24px;
    overflow-y: auto;
    overflow-x: hidden;
}

.markdown {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    h1,
    h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 32px;
    }

    h3,
    h4,
    h5,
    h6 {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 16px;
    }

    p {
        margin: 0 0 16px;

        & + h1,
        & + h2,
        & + h3,
        & + h4,
        & + h5,
        & + h6 {
            margin-top: 32px;
        }
    }

    a {
        word-break: break-word;
    }
}
