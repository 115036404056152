@import 'src/styles/colors.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eaeaea;
}

.headerTitle {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding-right: 8px;
    color: $black;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

.headerBackButton {
    background: none;
    border: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background: url(./images/arrow.svg) center center / 14px 14px no-repeat;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:hover {
        text-decoration: underline;
    }
}

.headerButton {
    height: 24px;
    padding: 5px 12px;
}

.content {
    flex: 1;
    margin: 0 -24px -24px;
    padding: 16px 24px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.businessResults {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
}

.card {
    display: flex;
    padding: 16px 0;
    border-top: 1px solid #eaeaea;
    cursor: pointer;
}

.cardInfo {
    flex: 1;
    padding-right: 16px;
}

.cardName {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.cardRecord {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.cardRating {
    margin-right: 6px;
}

.cardImage {
    position: relative;
    width: 140px;
    height: 140px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 140px;
        height: 140px;
        background: url(./images/empty-image.svg) center center / 40px 40px no-repeat;
        opacity: 0.3;
        border: 1px solid rgba(#103040, 0.3);
        border-radius: 10px;
    }

    img {
        position: relative;
        width: 140px;
        height: 140px;
        border-radius: 10px;
        object-fit: cover;
    }
}

.cityDetailsWidget {
    display: flex;
    min-height: 20px;
}

.businessRating {
    margin-bottom: 0;
}

.businessReviews {
    font-size: 10px;
    color: $grey;
}
