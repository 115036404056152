@import 'src/styles/colors.scss';

.app-reviewform{
    display: flex;
    flex-direction: column;
}
.app-reviewform__step{
    margin: 0px 0px 4px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(16, 48, 64, 0.5);
}

.app-reviewform__step-title{
    margin: 0px 0px 7px 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #103040;
}

.app-reviewform--devider{
    background: #F0F0F0;
    height: 1px;
    margin: 9px 0px 10px 0px;
}


.app-reviewform__submit-wrapper{
    margin-top: 5px;
}

.app-reviewform__selecs-wrapper {
    border-top: 1px solid $light-grey;
    border-bottom: 1px solid $light-grey;
    margin: 9px 0px;
}
.app-reviewform__review{
    margin-bottom: 9px;
    background: #F0F0F0;
    border-radius: 10px;
    border: none;
    resize: none;
    min-height: 82px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #103040;
    padding: 16px 9px 16px 9px;
}
