@import 'src/styles/colors.scss';

.sectionTitleWrapper {
    display: flex;
    align-items: center;
}

.title {
    font-weight: bold;
    font-size: 16px;
}

.questionIcon {
    font-size: 14px;
    line-height: 14px;
    color: $grey;
    cursor: pointer;
}
