@import 'src/styles/colors.scss';

.modal {
    position: absolute;
    left: 426px;
    top: 0;
    bottom: 0;
    width: 540px;
    z-index: -1;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0 48px;
    background-color: $white;
    border-radius: 0 20px 20px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 0 16px;
    border-bottom: 1px solid #eaeaea;
}

.content {
    flex: 1;
    margin: 0 -24px;
    padding: 0 24px 24px;
    overflow-y: auto;
    overflow-x: hidden;
}

.county {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
}

.state {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin-top: 8px;
    color: $grey;
}

.close {
    margin-left: 16px;
    background: none;
    border: 0;

    &:active,
    &:focus,
    &:hover {
        cursor: pointer;
        outline: none;
    }

    &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('../../images/close.svg');
        background-size: 24px 24px;
        background-position: center;
        background-repeat: no-repeat;
    }
}
