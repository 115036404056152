@import 'src/styles/colors.scss';

.container {
    margin-top: 24px;
}

.weatherIcon {
    vertical-align: middle;
}

.currentTemp {
    font-size: 36px;
    line-height: 42px;
    color: #000;
}

.currentParams {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.currentParam {
    font-size: 12px;
    line-height: 14px;
    margin-right: 10px;
    color: $grey;
}

.dailyWeather {
    display: flex;
    flex-direction: row;
}

.dayWeather {
    flex: 1;
    padding-right: 5px;
    overflow: hidden;
}

.dayWeather:last-child {
    padding-right: 0;
}

.dayWeatherIcon {
    text-align: center;
}

.dayWeatherWeakday {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.dayWeatherTemp {
    text-align: center;
}

.dayWeatherTempDay {
    margin-right: 5px;
    display: inline;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}

.dayWeatherTempNight {
    display: inline;
    font-size: 12px;
    line-height: 14px;
    color: #666;
}
