@import 'src/styles/colors.scss';

.menuItem {
    display: flex;
    position: relative;
    flex: 1;
    padding: 16px 0;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: $white;
    cursor: pointer;
    text-decoration: none;
    color: $black;
    border-bottom: 1px solid $light-grey;

    &._active {
        &::before {
            height: 2px;
        }
    }

    &:hover {
        &::before {
            height: 2px;
        }
    }

    &::before {
        position: absolute;
        content: '';
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 0px;
        background-color: $black;
    }
}

.icon {
    margin-right: 4px;
}

.didabled {
    cursor: default;
    pointer-events: none;
    color: $grey;
}