@import 'src/styles/colors.scss';

.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 24px 24px;

    @media screen and (max-width: 1023px) {
        padding: 0 16px 24px;
    }
}

.nav {
    display: flex;

    @media screen and (max-width: 1023px) {
        width: 100%;
        order: 1;
    }
}

.navItem {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: $black;
    margin-right: 16px;
    text-decoration: none;

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &._active {
        text-decoration: underline;

        &._logo {
            text-decoration: none;
        }
    }
}

.mapLegend {
    @media screen and (max-width: 1023px) {
        width: 100%;
        order: 0;
        margin-bottom: 24px;
    }
}
